import intlTelInput from 'intl-tel-input';

export function getCountriesForSpecificCase(caseName) {
  if (caseName === 'mobile-money-countries') {
    return ['BJ', 'CI', 'CM', 'ML', 'NE', 'SN', 'TG']; // List of countries for fedapay-countries
  }

  return [];
}

export let iti;
document.addEventListener("turbo:load", function() {
  var input = document.querySelector("input[type=tel]");

  if (!input) return;

  var inputTarget = document.querySelector(input.dataset.target);
  var specificCountries = input.dataset.specificCountries;
  var onlyCountries = specificCountries ? getCountriesForSpecificCase(specificCountries) : undefined;

  var options = {
    initialCountry: "auto",
    geoIpLookup: function(success, failure) {
      $.get("https://ipinfo.io?token=8ba4bdbd451b4f", function() {}, "jsonp").always(function(resp) {
        var countryCode = (resp && resp.country) ? resp.country : "us";
        success(countryCode);
      });
    },
    preferredCountries: [],
    utilsScript: window.intlTelInputUtilsPath
  };

  if (onlyCountries) {
    options.onlyCountries = onlyCountries;
  }

  iti = intlTelInput(input, options);

  var handleChange = function() {
    inputTarget.value = iti.isValidNumber() ? iti.getNumber() : '';
    return false;
  };

  // listen to "keyup", but also "change" to update when the user selects a country
  input.addEventListener('change', handleChange);
  input.addEventListener('keyup', handleChange);
});
