import Rails from '@rails/ujs';
import { getCountriesForSpecificCase, iti } from './tel_input';
import { displaySpinner, hideSpinner } from './packs/application';

const KKIAPAY_SUPPORTED_COUNTRIES = ['BJ', 'CI', 'TG', 'SN'];
const NOKASH_SUPPORTED_COUNTRIES = ['CM'];

document.addEventListener("turbo:load", () => {
  const operatorSelect = document.querySelector('#mobile-money-operator');
  if (operatorSelect) {
    operatorSelect.addEventListener('change', () => {
      const selectedOperator = operatorSelect.value;
      updateFlagByOperator(selectedOperator);
    });
  }

  const cardElementContainer = document.getElementById('card-element');
  if (cardElementContainer) {
    initializeStripeElements();
  }

  initializeMobileMoneyForm();
});

function initializeStripeElements() {
  const form = document.querySelector('#charge-credit-card-form');
  if (form) {
    const stripePublishableKey = document.querySelector("meta[name='stripe-publishable-key']").content;
    const stripe = Stripe(stripePublishableKey);

    const elements = stripe.elements({ locale: 'fr' });
    const cardElement = elements.create('card', { hidePostalCode: true });
    cardElement.mount('#card-element');

    cardElement.addEventListener('change', (event) => {
      const displayError = form.querySelector('#card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    // Add event listener to form submit button
    const submitButton = form.querySelector('#credit-card-submit-button');
    const spinner = submitButton.querySelector('.spinner-border');
    form.addEventListener('submit', async (event) => {
      event.preventDefault();
      if (spinner) {
        displaySpinner(spinner);
      }
      submitButton.disabled = true;

      const cardHolderName = form.querySelector('#card-holder-name').value;
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardHolderName,
        },
      });

      if (error) {
        flashAlert('Erreur dans le mode de paiement : ' + error);
        if (spinner) {
          hideSpinner(spinner);
        }
        submitButton.disabled = false;
      } else {
        const orderId = $('input[name="order-id"]').val();
        fetch('/orders/confirm_payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
          },
          body: JSON.stringify({
            payment_method_id: paymentMethod.id,
            order_id: orderId,
            payment_method: "credit-card"
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.error) {
              if (spinner) {
                hideSpinner(spinner);
              }
              submitButton.disabled = false;
              flashAlert('Erreur de confirmation de paiement : ' + result.error);
            } else {
              if (spinner) {
                hideSpinner(spinner);
              }
              submitButton.disabled = false;
              Turbo.visit(result.redirectionUrl);
            }
          });
      }
    });
  }
}

function initializeMobileMoneyForm() {
  const form = document.querySelector('#charge-mobile-money-form');
  if (!form) return;

  const submitButton = form.querySelector('#mobile-money-submit-button');
  const spinner = submitButton.querySelector('.spinner-border');

  form.addEventListener('submit', async (event) => {
    event.preventDefault();
    if (spinner) displaySpinner(spinner);
    submitButton.disabled = true;

    const orderId = form.querySelector('input[name="order-id"]').value;
    const operator = form.querySelector('#mobile-money-operator').value;
    const fullPhoneNumber = form.querySelector('#mobile_money_phone').value;
    const phoneNumber = getPhoneNumberWithoutPrefix(operator, fullPhoneNumber);
    const { countryCode } = operatorMapping[operator];
    const paymentGateway = choosePaymentGateway(countryCode);
    const productId = form.querySelector('input[name="product-id"]').value;
    const productType = form.querySelector('input[name="product-type"]').value;
    const orderData = {
      order_id: orderId,
      operator: operator,
      phone_number: phoneNumber,
      country_code: countryCode,
      payment_method: 'mobile-money',
    };

    if (paymentGateway == "kkiapay") {
      handleKkiapayPayment(orderData, productId, productType);
    } else if (paymentGateway == "nokash") {
      handleNokashPayment(orderData);
    } else if (paymentGateway == "fedapay") {
      handleFedapayPayment(orderData);
    }
  });
}

function handleKkiapayPayment(orderData, productId, productType) {
  const kkiapayPublicKey = document.querySelector("meta[name='kkiapay-public-key']").content;

  Rails.ajax({
    type: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken(),
    },
    url: '/orders/confirm_payment',
    data: JSON.stringify(orderData),
    success: (result) => {
      openKkiapayWidget({
        amount: parseInt(result.amount),
        fullname: result.fullname,
        email: result.email,
        key: kkiapayPublicKey,
        sandbox: result.sandbox,
        phone: orderData.phone_number,
        callback: result.callback,
        data: {
          order_id: result.orderId,
          product_id: productId,
          product_type: productType,
        },
      });
    },
    error: (result) => flashAlert(result.notice),
  });
}

function handleFedapayPayment(orderData) {
  handleMobileMoneyPayment(orderData)
}

function handleNokashPayment(orderData) {
  handleMobileMoneyPayment(orderData)
}

function handleMobileMoneyPayment(orderData) {
  const form = document.querySelector('#charge-mobile-money-form');
  if (!form) return;

  const submitButton = form.querySelector('#mobile-money-submit-button');
  const spinner = submitButton.querySelector('.spinner-border');

  Rails.ajax({
    type: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken(),
    },
    url: `/orders/confirm_payment`,
    data: JSON.stringify(orderData),
    success: (result) => {
      if (result.status == 'success') {
        if (spinner) hideSpinner(spinner);
        submitButton.disabled = false;
        Turbo.visit(result.redirectionUrl);
      } else if (result.status == 'pending') {
        checkOrderStatus(result.orderId);
      } else {
        flashAlert('Erreur de confirmation de paiement : ' + result.error);
        if (spinner) hideSpinner(spinner);
        submitButton.disabled = false;
      }
    },
    error: (result) => {
      flashAlert('Erreur lors de la demande de paiement : ' + result.notice);
      if (spinner) hideSpinner(spinner);
      submitButton.disabled = false;
    },
  });
}

const operatorMapping = {
  'mtn': { countryCode: 'BJ', prefix: '+229' },
  'moov': { countryCode: 'BJ', prefix: '+229' },
  'mtn_ci': { countryCode: 'CI', prefix: '+225' },
  'moov_tg': { countryCode: 'TG', prefix: '+228' },
  'togocel': { countryCode: 'TG', prefix: '+228' },
  'orange_ci': { countryCode: 'CI', prefix: '+225' },
  'orange_sn': { countryCode: 'SN', prefix: '+221' },
  'free_sn': { countryCode: 'SN', prefix: '+221' },
  'airtel_ne': { countryCode: 'NE', prefix: '+227' },
  'orange_ml': { countryCode: 'ML', prefix: '+223' },
  'mtn_cm': { countryCode: 'CM', prefix: '+237' },
  'orange_cm': { countryCode: 'CM', prefix: '+237' },
};

function updateFlagByOperator(operator) {
  const countryCode = operatorMapping[operator].countryCode;
  iti.setCountry(countryCode);
}

function getPhoneNumberWithoutPrefix(operator, phoneNumber) {
  const { prefix } = operatorMapping[operator];

  if (phoneNumber.startsWith(prefix)) {
    return phoneNumber.slice(prefix.length);
  }

  document.querySelector('#mobile-money-submit-button').disabled = false;
  document.querySelector('.spinner-border').style.display = 'none';
  flashAlert("Il y a un problème avec ton numéro de téléphone. Vérifie ton numéro et réessaye ! Si cela persiste contacte nous.");
  throw new Error(`Le numéro de téléphone ne commence pas par le préfixe attendu : ${prefix}`);
}

export function flashAlert(message) {
  let container = document.getElementById('inscription-program-flash-alerts');

  let alertBox = document.createElement('div');
  alertBox.className = 'alert alert-danger rounded-0 text-center alert-dismissible fade show';
  alertBox.role = 'alert';
  alertBox.innerHTML = `
    ${message}
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  `;

  container.appendChild(alertBox);
}

var checkInterval = 5000;

function checkOrderStatus(orderId) {
  const form = document.querySelector('#charge-mobile-money-form');
  if (!form) return;

  const submitButton = form.querySelector('#mobile-money-submit-button');
  const spinner = submitButton.querySelector('.spinner-border');

  Rails.ajax({
    type: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
    url: `/orders/check_order_status`,
    data: JSON.stringify({
      order_id: orderId
    }),
    success: (result) => {
      if (result.status === 'success') {
        if (spinner) {
          hideSpinner(spinner);
        }
        submitButton.disabled = false;
        Turbo.visit(result.redirectionUrl);
      } else if (result.status === 'pending') {
        setTimeout(() => checkOrderStatus(orderId), checkInterval);
      } else {
        flashAlert('Erreur de confirmation de paiement : ' + result.error);
        if (spinner) {
          hideSpinner(spinner);
        }
        submitButton.disabled = false;
      }
    },
    error: (result) => {
      flashAlert('Erreur dans la vérification du statut de la commande : ' + result.notice);
      if (spinner) {
        hideSpinner(spinner);
      }
      submitButton.disabled = false;
    }
  });
}

function choosePaymentGateway(countryCode) {
  if (KKIAPAY_SUPPORTED_COUNTRIES.includes(countryCode)) {
    return 'kkiapay';
  } else if (NOKASH_SUPPORTED_COUNTRIES.includes(countryCode)) {
    return 'nokash';
  } else {
    return 'fedapay';
  }
}
